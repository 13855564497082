/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable max-len */
/* eslint linebreak-style: ["error", "windows"] */

import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  Checkbox,
  Container,
  Grid,
  List,
  ListItemButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ScheduleMeeting } from 'react-schedule-meeting';
import { Box } from '@mui/system';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from 'moment-timezone';
import { ErrorBoundary } from 'react-error-boundary';
import TimezoneSelect from 'react-timezone-select';
import { useNavigate } from 'react-router-dom';
import InternalErrorBoundaryPage from '../../components/ErrorBoundary/InternalErrorBoudaryPage';
import ReactCornerRibbon from '../../components/Reservation/CornerRibbon';
import ConfirmationCard from '../../components/Reservation/ConfirmationCard';
import {
  convertFromUTCStringToZone,
  convertFromZoneToUTCString,
  timeSlotDifference
} from '../../util/commonFunctions';
import './Reservation.css';

// TODO Constants

export default function Reservation({
  reservations,
  availability,
  userId,
  username,
  fetchReservations
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [isDialogBugged, setIsDialogBugged] = useState(false);
  const [currentReservation, setCurrentReservation] = useState('');
  const [selectDurationDisable, setSelectDurationDisable] = useState(false);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [confirmation, setConfirmation] = useState(false);
  const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [durations, setDurations] = useState();
  const [selectedDuration, setSelectedDuration] = useState(0);
  const navigate = useNavigate();

  // TODO Simplifying functions {
  const getDateStringFormat = (date) =>
    new Date(date).toLocaleString('en-EN', { day: '2-digit', month: '2-digit', year: 'numeric' });
  const getTimeZoneString = () => (typeof timeZone === 'object' ? timeZone.value : timeZone);
  const getParsedVersionOfAvailabilityDateRange = (availabilityData) =>
    JSON.parse(availabilityData.availabilityDateRange);
  const getParsedVersionOfAvailabileWeekDaysAndTimes = (availabilityData) =>
    JSON.parse(availabilityData.availability).availability;
  const getCurrentTimeInCurrentTimeZoneForm = () => {
    const timeZoneString = getTimeZoneString();
    const currentTime = new Date();
    const currentTimeInUTCForm = convertFromZoneToUTCString(
      currentTime,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );

    return convertFromUTCStringToZone(currentTimeInUTCForm, timeZoneString);
  };
  /**
   * Returns the difference between two dates
   *
   * @param  {Date} date1  default the greater date.
   * @param  {Date} date2  default the minor date.
   * @returns positive integer if the 'date1' is greater than 'date2'.
   */
  const getDifferenceBetweenTwoDates = (date1, date2) => {
    const firstDate = moment(date1);
    const secondDate = moment(date2);

    return firstDate.diff(secondDate, 'days');
  };
  const getIfRangeStartDayBeforeToday = (rangeStartDate) =>
    moment(rangeStartDate) < moment(new Date());

  const createReservationsMapWithADayAsKey = (reservationsData, timeZoneString) => {
    const reservationsMap = [];
    reservationsData.forEach((reserv) => {
      const reservationStartTimeinCurrentTimezoneForm = convertFromUTCStringToZone(
        reserv.startTime,
        timeZoneString
      );
      const reservationEndTimeinCurrentTimezoneForm = convertFromUTCStringToZone(
        reserv.endTime,
        timeZoneString
      );
      const mapKey = getDateStringFormat(reservationStartTimeinCurrentTimezoneForm);
      if (reservationsMap[mapKey]) {
        reservationsMap[mapKey].push({
          startTime: reservationStartTimeinCurrentTimezoneForm,
          endTime: reservationEndTimeinCurrentTimezoneForm
        });
      } else {
        reservationsMap[mapKey] = [
          {
            startTime: reservationStartTimeinCurrentTimezoneForm,
            endTime: reservationEndTimeinCurrentTimezoneForm
          }
        ];
      }
    });
    return reservationsMap;
  };
  const getTheDayIndex = (date, startDiff, index) =>
    (new Date(date).getDay() + startDiff + index) % 7;
  const getIfTheDayIsCheckedInAvailability = (
    availabilityData,
    currentTimeInCurrentTimeZoneForm,
    startDiff,
    idx
  ) => {
    const dayIndex = getTheDayIndex(currentTimeInCurrentTimeZoneForm, startDiff, idx);
    if (availabilityData[dayIndex][dayIndex].checked) {
      return startDiff + idx;
    }
    return false;
  };
  const getListOfCurrentAvailableDayIntervals = (
    parsedAvailability,
    currentTimeInCurrentTimeZoneForm,
    timeZoneString,
    id,
    dayIndex
  ) => {
    let dayList = [];
    parsedAvailability[dayIndex][dayIndex].intervals.forEach((interval) => {
      const from = convertFromUTCStringToZone(interval.from, timeZoneString);
      const to = convertFromUTCStringToZone(interval.to, timeZoneString);
      const startTime = new Date(
        new Date(
          new Date(currentTimeInCurrentTimeZoneForm).setDate(
            new Date(currentTimeInCurrentTimeZoneForm).getDate() + id
          )
        ).setHours(new Date(from).getHours(), new Date(from).getMinutes(), 0, 0)
      );
      const endTime = new Date(
        new Date(
          new Date(currentTimeInCurrentTimeZoneForm).setDate(
            new Date(currentTimeInCurrentTimeZoneForm).getDate() + id
          )
        ).setHours(new Date(to).getHours(), new Date(to).getMinutes(), 0, 0)
      );
      dayList = [...dayList, { id, startTime, endTime }];
    });
    return dayList;
  };
  const getAvailableTimeSlots = (countOfDays, startDiff, availabilityData, reservationsData) => {
    const timeZoneString = getTimeZoneString();
    const reservationsMap = createReservationsMapWithADayAsKey(reservationsData, timeZoneString);
    const currentTimeInCurrentTimeZoneForm = getCurrentTimeInCurrentTimeZoneForm();
    const parsedAvailability = getParsedVersionOfAvailabileWeekDaysAndTimes(availabilityData);

    let newAvailableTimeSlots = [];

    Array(countOfDays)
      .fill()
      .map((_, idx) =>
        getIfTheDayIsCheckedInAvailability(
          parsedAvailability,
          currentTimeInCurrentTimeZoneForm,
          startDiff,
          idx
        )
      )
      .forEach((id) => {
        if (id || id === 0) {
          const currentDate = new Date(
            new Date(currentTimeInCurrentTimeZoneForm).setDate(
              new Date(currentTimeInCurrentTimeZoneForm).getDate() + id
            )
          );
          const dayIndex = new Date(currentDate).getDay();
          let currentAvailableDayIntervalsList = getListOfCurrentAvailableDayIntervals(
            parsedAvailability,
            currentTimeInCurrentTimeZoneForm,
            timeZoneString,
            id,
            dayIndex
          );

          const currentDayDateString = getDateStringFormat(currentDate);
          if (reservationsMap[currentDayDateString]) {
            const availableTimeSlotsLessUnavailableTimeSlots = timeSlotDifference(
              currentAvailableDayIntervalsList,
              reservationsMap[currentDayDateString]
            );
            currentAvailableDayIntervalsList = availableTimeSlotsLessUnavailableTimeSlots;
          }
          newAvailableTimeSlots = newAvailableTimeSlots.concat(currentAvailableDayIntervalsList);
        }
      });
    return newAvailableTimeSlots;
  };
  // } end of simplifying functions

  const handleSelectDuration = (index) => {
    setConfirmation(false);
    setSelectedDuration(index);
  };
  const handleChangeTimeZone = (value) => {
    setConfirmation(false);
    setTimeZone(value);
  };

  const handleCompleteReservation = (timeslot) => {
    setTimeout(() => {
      if (isMobile) {
        document.getElementById('confirm').scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);

    setCurrentReservation(timeslot);
    setConfirmation(true);
  };
  useEffect(() => {
    if (userId && username) {
      if (availability && reservations) {
        const parsedAvailabilityDateRange = getParsedVersionOfAvailabilityDateRange(availability);
        let availableDateRangeStartDate = new Date(parsedAvailabilityDateRange.startDate);
        const availableDateRangeEndDate = new Date(parsedAvailabilityDateRange.endDate);
        if (getIfRangeStartDayBeforeToday(availableDateRangeStartDate)) {
          availableDateRangeStartDate = new Date();
        }
        const countOfDays =
          getDifferenceBetweenTwoDates(availableDateRangeEndDate, availableDateRangeStartDate) + 1;
        let startDiff = getDifferenceBetweenTwoDates(availableDateRangeStartDate, new Date());

        if (startDiff < 0) {
          startDiff = 0;
        }

        let newAvailableTimeSlots = [];

        if (countOfDays > 0) {
          newAvailableTimeSlots = getAvailableTimeSlots(
            countOfDays,
            startDiff,
            availability,
            reservations
          );
        }
        setDurations(JSON.parse(availability.availability).durations.durations);
        setAvailableTimeSlots(newAvailableTimeSlots);
      }
    } else {
      navigate('/');
    }
  }, [availability, navigate, reservations, timeZone, userId, username]);

  useEffect(() => {
    if (availability) setTimeZone(JSON.parse(availability.timeZone));
  }, [availability]);

  return userId && username ? (
    <Box bgcolor="#FAFAFA" minHeight="100vh">
      <Container maxWidth="xl">
        <Grid container direction="column" spacing={2} justifyContent="center">
          <Grid item container xs={12} justifyContent="stretch">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card
                sx={{
                  position: 'relative',
                  margin: isMobile ? '8px 8px' : '16px 16px',
                  padding: 2,
                  borderRadius: '0px',
                  overflow: 'visible',
                  boxShadow: 3,
                  background: '#'
                }}>
                <ReactCornerRibbon />
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box display="flex" alignItems="center" gap={2} padding={0.5}>
                      <Avatar
                        sx={{ width: 40, height: 40 }}
                        src="https://storage.googleapis.com/download/storage/v1/b/linearbits-public/o/photos%2Fdff505a5-f079-4702-94d4-206a433a7c0e.svg?generation=1661410242190161&alt=media"
                      />
                      <Box display="flex" alignItems="center" gap={0.5}>
                        <Typography fontSize={20}>{username}</Typography>
                      </Box>
                    </Box>
                    <Box mt={1} mb={2}>
                      <Typography fontSize={12} color="#555">
                        Time zone
                      </Typography>
                      <TimezoneSelect
                        value={timeZone}
                        onChange={handleChangeTimeZone}
                        className="timeZone-select"
                      />
                    </Box>
                  </Grid>
                  {durations ? (
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                      <Typography fontSize={12} color="#555">
                        Meeting length
                      </Typography>

                      <List dense disablePadding component={Stack} direction="column">
                        {durations?.map(
                          (duration, index) =>
                            duration.checked && (
                              <ListItemButton
                                dense
                                key={`${duration.name}`}
                                sx={{
                                  display: 'flex',
                                  padding: '0px 2px',
                                  borderRadius: '5px',
                                  gap: 1
                                }}
                                onClick={() => handleSelectDuration(index)}
                                disabled={selectDurationDisable}>
                                <Checkbox
                                  checked={selectedDuration === index}
                                  icon={<CircleOutlinedIcon />}
                                  checkedIcon={<CheckCircleIcon />}
                                />
                                <Typography fontFamily="Fredoka">{duration.name}</Typography>
                              </ListItemButton>
                            )
                        )}
                      </List>
                    </Grid>
                  ) : null}
                </Grid>
                <Grid container direction="row" alignItems="stretch" spacing={0}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={confirmation ? 7 : 12}
                    lg={confirmation ? 7 : 12}
                    xl={confirmation ? 7 : 12}
                    sx={{ ml: isMobile ? 0 : -2 }}
                    style={{
                      transition: theme.transitions.create('all', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen
                      })
                    }}
                    id="ScheduleComp">
                    <ScheduleMeeting
                      startTimeListStyle="scroll-list"
                      // borderRadius={15}
                      // primaryColor='#4ACF50'
                      scheduleMeetingStyles={{
                        padding: 0,
                        background: 'transparent',
                        boxShadow: 'none',
                        height: '100%',
                        alignItems: 'stretch'
                      }}
                      eventDurationInMinutes={durations && durations[selectedDuration]?.value}
                      availableTimeslots={availableTimeSlots}
                      onStartTimeSelect={handleCompleteReservation}
                      onSelectedDayChange={() => setConfirmation(false)}
                      emptyListContentEl={
                        <Typography color="#bbb" fontFamily="Fredoka">
                          No times available
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    zeroMinWidth
                    xs={confirmation ? 12 : true}
                    sm={confirmation ? 12 : true}
                    md={confirmation ? 5 : true}
                    lg={confirmation ? 5 : true}
                    xl={confirmation ? 5 : true}
                    style={{
                      transition: theme.transitions.create('all', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen
                      })
                    }}
                    id="confirm">
                    <ErrorBoundary
                      FallbackComponent={InternalErrorBoundaryPage}
                      onReset={() => setIsDialogBugged(false)}
                      resetKeys={[isDialogBugged]}>
                      <Card
                        sx={{
                          display: confirmation ? '' : 'none',
                          padding: 1,
                          borderRadius: '0px',
                          margin: isMobile ? '8px 16px' : '16px -8px 16px -16px',
                          boxShadow: 3,
                          border: 'none'
                        }}>
                        <ConfirmationCard
                          fetchReservations={fetchReservations}
                          durations={durations}
                          currentReservation={currentReservation}
                          setCurrentReservation={setCurrentReservation}
                          selectedDuration={selectedDuration}
                          setConfirmation={setConfirmation}
                          setSelectDurationDisable={setSelectDurationDisable}
                          buttonColor="#3f5b85"
                          borderRadius="0px"
                          timeZone={timeZone}
                          userId={userId}
                        />
                      </Card>
                    </ErrorBoundary>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  ) : null;
}

Reservation.propTypes = {
  reservations: PropTypes.any,
  availability: PropTypes.any,
  userId: PropTypes.string.isRequired,
  fetchReservations: PropTypes.any.isRequired,
  username: PropTypes.string.isRequired
};
Reservation.defaultProps = {
  reservations: undefined,
  availability: undefined
};
