/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
/* eslint linebreak-style: ["error", "windows"] */

import { Refresh } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { Alert, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';

export default function InternalErrorBoundaryPage({ error, resetErrorBoundary }) {
  return (
    <Box width="100%">
      <Alert
        severity="error"
        action={
          <IconButton color="inherit" size="small" onClick={resetErrorBoundary}>
            <Refresh />
          </IconButton>
        }>
        Something went wrong
      </Alert>
      <Typography component="pre" align="center" color="tomato">
        {error.message}
      </Typography>
    </Box>
  );
}

InternalErrorBoundaryPage.propTypes = {
  error: PropTypes.any.isRequired,
  resetErrorBoundary: PropTypes.any.isRequired
};
