/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable max-len */
/* eslint linebreak-style: ["error", "windows"] */

import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { timeSlotDifference } from '../../util/commonFunctions';

export default function Test() {
  const availableTimeSlots = [
    {
      startTime: new Date('2023-01-01T09:00:00.000Z'),
      endTime: new Date('2023-01-01T12:00:00.000Z')
    },
    {
      startTime: new Date('2023-01-01T13:30:00.000Z'),
      endTime: new Date('2023-01-01T17:00:00.000Z')
    },
    {
      startTime: new Date('2023-01-01T18:00:00.000Z'),
      endTime: new Date('2023-01-01T19:30:00.000Z')
    }
  ];

  const unavailableTimeSlots = [
    {
      startTime: new Date('2023-01-01T09:00:00.000Z'),
      endTime: new Date('2023-01-01T09:15:00.000Z')
    },
    {
      startTime: new Date('2023-01-01T10:00:00.000Z'),
      endTime: new Date('2023-01-01T10:15:00.000Z')
    }
  ];
  const [
    availableTimeSlotsLessUnavailableTimeSlots,
    setAvailableTimeSlotsLessUnavailableTimeSlots
  ] = useState();

  useEffect(() => {
    const res = timeSlotDifference(availableTimeSlots, unavailableTimeSlots);

    setAvailableTimeSlotsLessUnavailableTimeSlots(res);
  }, []);
  return (
    <Box>
      {availableTimeSlotsLessUnavailableTimeSlots && (
        <ReactJson src={availableTimeSlotsLessUnavailableTimeSlots} />
      )}
    </Box>
  );
}

// const availableTimeSlots = [
//  [
//   {
//    "startTime": "2023-01-01T09:00:00.000Z",
//    "endTime": "2023-01-01T09:15:00.000Z"
//   },
//   {
//    "startTime": "2023-01-01T09:45:00.000Z",
//    "endTime": "2023-01-01T12:00:00.000Z"
//   },
//   {
//    "startTime": "2023-01-01T13:30:00.000Z",
//    "endTime": "2023-01-01T14:15:00.000Z"
//   },
//   {
//    "startTime": "2023-01-01T15:45:00.000Z",
//    "endTime": "2023-01-01T16:00:00.000Z"
//   },
//   {
//    "startTime": "2023-01-01T16:30:00.000Z",
//    "endTime": "2023-01-01T17:00:00.000Z"
//   },
//   {
//    "startTime": "2023-01-01T18:00:00.000Z",
//    "endTime": "2023-01-01T19:30:00.000Z"
//   }
//  ]
// ];

// const unavailableTimeSlots = [
//   {
//  startTime: new Date('2023-01-01T09:15:00.000Z'),
//  endTime: new Date('2023-01-01T09:45:00.000Z'),
//   },
//   {
//  startTime: new Date('2023-01-01T14:15:00.000Z'),
//  endTime: new Date('2023-01-01T15:45:00.000Z'),
//   },
//   {
