/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
/* eslint linebreak-style: ["error", "windows"] */

import { ArrowBack, Close, DateRange, Public, Upload } from '@mui/icons-material';
import {
  Button,
  CardHeader,
  CircularProgress,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton
} from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useCallback, useState } from 'react';
import validator from 'validator';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PropTypes from 'prop-types';
import { useAuth } from '../../AuthContext/AuthContext';
import Env from '../../util/Env';
import { TextEditor } from '../Tiptap/Editor';
import { GetFromToString, convertFromZoneToUTCString } from '../../util/commonFunctions';

export default function ConfirmationCard({
  fetchReservations,
  durations,
  selectedDuration,
  currentReservation,
  setCurrentReservation,
  setConfirmation,
  setSelectDurationDisable,
  buttonColor,
  borderRadius,
  timeZone,
  userId
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { loggedInUser, signInAnonymously, token } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const timeZoneStr = typeof timeZone === 'object' ? timeZone.value : timeZone;
  const [file, setFile] = useState();
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [endAdornmentEmail, setEndAdornmentEmail] = useState(false);
  const [endAdornmentName, setEndAdornmentName] = useState(false);

  const handleCheckEmail = () => {
    if (email) {
      if (validator.isEmail(email)) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
      setEndAdornmentEmail(true);
    } else {
      setEmailError(false);
      setEndAdornmentEmail(false);
    }
  };
  const handleCheckName = () => {
    if (name) {
      if (validator.isAlpha(name, undefined, { ignore: ' ' })) {
        setNameError(false);
      } else {
        setNameError(true);
      }
      setEndAdornmentName(true);
    } else {
      setNameError(false);
      setEndAdornmentName(false);
    }
  };

  const handleUploadCvFile = useCallback((event) => {
    if (event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  }, []);

  const handleReset = () => {
    setEmail('');
    setName('');
    setDescription('');
    setFile();
    setCurrentReservation('');
  };
  const handleCancel = () => {
    const scheduleComp = document.getElementById('ScheduleComp');
    scheduleComp.style.pointerEvents = '';
    setSelectDurationDisable(false);
    setConfirmation(false);
  };

  const handleConfirm = async () => {
    setLoading(true);
    await handleCheckEmail();
    await handleCheckName();
    if (!nameError && !emailError) {
      let endTime;
      if (currentReservation.splitTimeslot[1]) {
        endTime = currentReservation.splitTimeslot[1].startTime;
      } else {
        endTime = currentReservation.availableTimeslot.endTime;
      }
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        axios
          .post(`${Env().url}/sketch/uploadCV`, formData, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          .then((res) => {
            if (res.data) {
              axios
                .post(`${Env().url}/userschedule/reservation`, {
                  startTime: convertFromZoneToUTCString(currentReservation.startTime, timeZoneStr),
                  endTime: convertFromZoneToUTCString(endTime, timeZoneStr),
                  userId,
                  reservation: JSON.stringify({
                    email,
                    name,
                    description,
                    cv: res.data.id
                  }),
                  timeZone: JSON.stringify({ value: timeZoneStr })
                })
                .then(() => {
                  setLoading(false);
                  handleReset();
                  handleCancel();
                  enqueueSnackbar('Reservation is saved successfully!', {
                    variant: 'reservationDone',
                    autoHideDuration: 7000,
                    reservation: {
                      startTime: currentReservation.startTime,
                      endTime,
                      email,
                      name,
                      description,
                      timeZone: timeZoneStr
                    }
                  });
                  fetchReservations({ url: `${Env().url}/userschedule/reservation/${userId}` });
                })
                .catch(() => {
                  setLoading(false);
                  enqueueSnackbar('failed! try again later', {
                    variant: 'error'
                  });
                });
            }
          })
          .catch(() => {
            enqueueSnackbar("couldn't upload cv", { variant: 'error' });
            enqueueSnackbar('you can clear cv and save your reservation', {
              variant: 'warning'
            });
            setLoading(false);
          });
      } else {
        axios
          .post(`${Env().url}/userschedule/reservation`, {
            startTime: convertFromZoneToUTCString(currentReservation.startTime, timeZoneStr),
            endTime: convertFromZoneToUTCString(endTime, timeZoneStr),
            userId,
            reservation: JSON.stringify({ email, name, description }),
            timeZone: JSON.stringify({ value: timeZoneStr })
          })
          .then(() => {
            setLoading(false);
            handleReset();
            handleCancel();
            enqueueSnackbar('Reservation is saved successfully!', {
              variant: 'reservationDone',
              autoHideDuration: 7000,
              reservation: {
                startTime: currentReservation.startTime,
                endTime,
                email,
                name,
                description,
                timeZone: timeZoneStr
              }
            });
            fetchReservations({ url: `${Env().url}/userschedule/reservation/${userId}` });
          })
          .catch(() => {
            setLoading(false);
            enqueueSnackbar('failed! try again later', { variant: 'error' });
          });
      }
    }
  };

  useEffect(() => {
    if (!loggedInUser) {
      signInAnonymously();
    }
  }, []);

  return (
    <>
      {durations && (
        <Box display="flex" alignItems="flex-start" flexWrap="wrap">
          <Box
            p={isMobile ? '8px 0px' : '0px 8px 8px'}
            color="#00000080"
            display="flex"
            alignItems="center"
            gap={1}>
            <IconButton onClick={handleCancel}>
              <ArrowBack />
            </IconButton>
            <CardHeader
              title={
                <Typography fontFamily="Fredoka" color="#000" variant="h5" fontWeight={600}>
                  {`${durations[selectedDuration].name} `} Meeting
                </Typography>
              }
              sx={{ padding: isMobile ? 0 : 0.5 }}
            />
          </Box>
          <Box display="flex" gap={1} flexWrap="wrap" width="100%">
            <Box display="flex" gap={1}>
              <DateRange />
              <GetFromToString
                time={currentReservation.startTime}
                duration={durations[selectedDuration].value}
              />
            </Box>
            <Box display="flex" gap={1}>
              <Public />
              <Typography fontFamily="Fredoka" fontWeight={600} fontSize={isMobile ? 14 : 15}>
                {timeZoneStr}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" p={1} justifyContent="center" width="100%">
            <Box display="flex" flexDirection="column" width="100%" flexWrap="wrap" gap={1}>
              <TextField
                error={emailError}
                helperText="example@domain.com"
                InputProps={{
                  endAdornment:
                    endAdornmentEmail &&
                    email &&
                    (!emailError ? (
                      <CheckCircleOutlineIcon color="success" fontSize="small" />
                    ) : (
                      <CancelOutlinedIcon color="error" fontSize="small" />
                    ))
                }}
                variant="outlined"
                label="Email"
                required
                size="small"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                onBlur={handleCheckEmail}
              />
              {/* <FormHelperText error={emailError}></FormHelperText> */}
              <TextField
                error={nameError}
                helperText="Only alphabet letters"
                InputProps={{
                  endAdornment:
                    endAdornmentName &&
                    name &&
                    (!nameError ? (
                      <CheckCircleOutlineIcon color="success" fontSize="small" />
                    ) : (
                      <CancelOutlinedIcon color="error" fontSize="small" />
                    ))
                }}
                variant="outlined"
                label="Name"
                required
                size="small"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                onBlur={handleCheckName}
              />
            </Box>
            <Typography color="#00000080" mt={1}>
              More info
            </Typography>
            <Box
              border="1px solid #EEE"
              bgcolor="#FFF"
              pl={1}
              pr={1}
              pb={1}
              height={213}
              maxHeight={213}
              overflow="auto"
              borderRadius={2}
              className="TiptapEditor">
              <TextEditor
                notes={description}
                setNotes={setDescription}
                editable
                extensions={['textStyles', 'heading', 'lists', 'other']}
              />
            </Box>
            <Box
              mt={1}
              display="flex"
              justifyContent={isMobile ? 'center' : 'space-between'}
              gap={isMobile ? 2 : 0}>
              <Box display="flex" alignItems="center" gap={1}>
                <Button
                  variant="outlined"
                  component="label"
                  size="small"
                  sx={{ textTransform: 'none' }}
                  endIcon={!file && <Upload fontSize="small" />}>
                  {file ? (
                    file.name
                  ) : (
                    <>
                      <Typography>File attachment</Typography>
                      <input
                        accept="application/pdf"
                        type="file"
                        onChange={handleUploadCvFile}
                        hidden
                      />
                    </>
                  )}
                </Button>
                {file && (
                  <IconButton onClick={() => setFile('')} size="small" color="primary">
                    <Close fontSize="small" />
                  </IconButton>
                )}
              </Box>
              <Button
                variant="contained"
                startIcon={loading ? <CircularProgress size={20} sx={{ color: '#fff' }} /> : null}
                sx={{
                  textTransform: 'none',
                  borderRadius,
                  background:
                    !email || !name || loading || emailError || nameError
                      ? `${buttonColor}80 !important`
                      : `${buttonColor} !important`
                }}
                onClick={handleConfirm}
                disabled={!email || !name || loading || emailError || nameError}>
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

ConfirmationCard.propTypes = {
  fetchReservations: PropTypes.any.isRequired,
  durations: PropTypes.any.isRequired,
  selectedDuration: PropTypes.any.isRequired,
  currentReservation: PropTypes.any.isRequired,
  setCurrentReservation: PropTypes.any.isRequired,
  setConfirmation: PropTypes.any.isRequired,
  setSelectDurationDisable: PropTypes.any.isRequired,
  buttonColor: PropTypes.string.isRequired,
  borderRadius: PropTypes.string.isRequired,
  timeZone: PropTypes.any.isRequired,
  userId: PropTypes.any.isRequired
};
