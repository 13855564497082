/* eslint-disable react/display-name */
/* eslint-disable react/react-in-jsx-scope */
import {
  Card,
  CardActions,
  Collapse,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import PropTypes from 'prop-types';
import { SnackbarContent, useSnackbar } from 'notistack';
import { forwardRef, useCallback, useState } from 'react';
import clsx from 'clsx';
import { Close, DateRange, ExpandMore, Public } from '@mui/icons-material';
import './reservationDone.css';
import { Box } from '@mui/system';
import { GetFromToString } from '../../util/commonFunctions';
import { TextEditor } from '../Tiptap/Editor';

export const ReservationDoneNotification = forwardRef(({ id, reservation, snackMessage }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const message = snackMessage;
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const duration = Math.ceil(
    (new Date(reservation.endTime) - new Date(reservation.startTime)) / (1000 * 60)
  );

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref} className="root">
      <Card className="card" style={{ backgroundColor: '#fddc6c' }}>
        <CardActions classes={{ root: 'actionRoot' }}>
          <Typography variant="body2" fontFamily="Fredoka" className="typography">
            {message}
          </Typography>
          <div className="icons">
            <IconButton
              aria-label="Show more"
              size="small"
              className={clsx('expand', {
                expandOpen: expanded
              })}
              onClick={handleExpandClick}>
              <ExpandMore />
            </IconButton>
            <IconButton size="small" className="expand" onClick={handleDismiss}>
              <Close fontSize="small" />
            </IconButton>
          </div>
        </CardActions>
        <Collapse in={expanded} timeout="auto">
          <Paper sx={{ p: 1 }}>
            <Typography align="center" variant="h6" fontFamily="Fredoka" fontWeight={600}>
              {reservation.name}
            </Typography>
            <Typography
              align="center"
              variant="body1"
              fontFamily="Fredoka"
              fontSize={12}
              gutterBottom>
              {reservation.email}
            </Typography>
            <Box display="flex" gap={1}>
              <DateRange />
              <GetFromToString time={reservation.startTime} duration={duration} />
            </Box>
            <Box display="flex" gap={1}>
              <Public />
              <Typography
                fontFamily="Fredoka"
                fontWeight={600}
                fontSize={isMobile ? 14 : 15}
                component="p">
                {typeof reservation.timezone === 'object'
                  ? reservation.timezone.value
                  : reservation.timeZone}
              </Typography>
            </Box>

            {reservation.description && (
              <>
                <Typography fontWeight={600} fontFamily="Fredoka" mt={1} color="#aaa">
                  Summary
                </Typography>
                <TextEditor notes={reservation.description} editable={false} />
              </>
            )}
            {reservation.cv && (
              <Typography fontFamily="Fredoka" color="#aaf">
                <Typography fontWeight={600} component="strong">
                  •
                </Typography>{' '}
                Cv is attached
              </Typography>
            )}
          </Paper>
        </Collapse>
      </Card>
    </SnackbarContent>
  );
});

ReservationDoneNotification.propTypes = {
  id: PropTypes.any.isRequired,
  reservation: PropTypes.any.isRequired,
  snackMessage: PropTypes.any.isRequired,
  props: PropTypes.any.isRequired
};
