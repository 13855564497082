/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import { Typography } from '@mui/material';
import { useState } from 'react';
import CornerRibbon from 'react-corner-ribbon';

export default function ReactCornerRibbon() {
  const [hovered, setHovered] = useState(false);

  return (
    <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <a href="/" target="_blank">
        <CornerRibbon
          position="top-right" // OPTIONAL, default as "top-right"
          fontColor="#f0f0f0" // OPTIONAL, default as "#f0f0f0"
          backgroundColor="#1f1f1f" // OPTIONAL, default as "#2c7"
          containerStyle={{ desplay: 'flex', flexDirection: 'column', alignItems: 'center' }} // OPTIONAL, style of the ribbon
          style={{ scale: hovered ? '110%' : '', opacity: hovered ? '100%' : '90%' }} // OPTIONAL, style of ribbon content
          className="">
          <Typography fontSize={hovered ? 8 : 10}> by</Typography>
          <Typography fontFamily="Fredoka" fontWeight={hovered ? 700 : 0}>
            Linearbits
          </Typography>
        </CornerRibbon>
      </a>
    </div>
  );
}
