/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */

import React from 'react';
import ReactDOM from 'react-dom/client';
import PropTypes from 'prop-types';
import './index.css';
import { SnackbarProvider } from 'notistack';
import { Slide } from '@mui/material';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './AuthContext/AuthContext';
import { ReservationDoneNotification } from './components/Reservation/ReservationDoneNotification';

const autoHideDuration = 2000;
const maxSnacks = 3;

function SnacksProvider({ children }) {
  return (
    <SnackbarProvider
      preventDuplicate
      maxSnack={maxSnacks}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      TransitionComponent={Slide}
      autoHideDuration={autoHideDuration}
      Components={{
        reservationDone: ReservationDoneNotification
      }}>
      {children}
    </SnackbarProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <SnacksProvider>
        <App />
      </SnacksProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

SnacksProvider.propTypes = {
  children: PropTypes.any.isRequired
};
