/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
/* eslint linebreak-style: ["error", "windows"] */

import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  FormHelperText,
  LinearProgress,
  TextField,
  Typography
} from '@mui/material';
import { Box, Container } from '@mui/system';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Env from '../../util/Env';

export default function Dashboard({
  fetchAvailability,
  fetchReservations,
  setUsername,
  reservationLoading,
  availabilityLoading,
  setUserId,
  reservationsError,
  availabilityError
}) {
  const navigate = useNavigate();
  const [userIdText, setUserIdText] = useState('');
  const [usernameText, setUsernameText] = useState('');
  const [error, setError] = useState(false);
  const { userId, username } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const handleNavigate = async (e) => {
    e?.preventDefault();
    setError(false);
    if ((userIdText && usernameText) || (username && userId)) {
      const { data: innerAvailability } = await fetchAvailability({
        url: `${Env().url}/userschedule/availability/${userIdText || userId}`
      });
      await fetchReservations({
        url: `${Env().url}/userschedule/reservation/${userIdText || userId}`
      });
      if (username) {
        await setUsername(username);
      }
      if (userId) {
        await setUserId(userId);
      }
      if (!reservationsError && !availabilityError && innerAvailability) {
        navigate('/Reservation');
      } else {
        if (reservationsError) {
          enqueueSnackbar(reservationsError.message, { variant: 'error' });
        }
        if (availabilityError) {
          enqueueSnackbar(availabilityError.message, { variant: 'error' });
        }
        if (innerAvailability) {
          enqueueSnackbar("Wrong user ID or this user doesn't having free time", {
            variant: 'error'
          });
        }
      }
    } else {
      enqueueSnackbar('You should enter credintials first\n Or Use valid URL', {
        variant: 'error'
      });
      setError(true);
    }
  };

  useEffect(() => {
    if (username && userId) {
      setUserIdText(userId);
      setUsernameText(username);
      setUsername(username);
      setUserId(userId);
      handleNavigate();
    } else {
      enqueueSnackbar('You should enter credintials first\n Or Use valid URL', {
        variant: 'error'
      });
    }
  }, []);

  return (
    <>
      <Box sx={{ width: '100%', height: 5 }}>
        {(reservationLoading || availabilityLoading) && (
          <LinearProgress sx={{ display: 'block' }} />
        )}
      </Box>

      <Container maxWidth="md">
        <form onSubmit={handleNavigate} id="Form">
          <Box display="flex" flexDirection="column" marginTop="25%" gap={1}>
            <Typography variant="h3" fontFamily="Fredoka" align="center">
              Please fill the form and Go!
            </Typography>
            <TextField
              color="primary"
              variant="outlined"
              value={userIdText}
              label="User ID"
              required
              onChange={(e) => setUserIdText(e.target.value)}
              fullWidth
            />
            <TextField
              color="primary"
              variant="outlined"
              value={usernameText}
              label="Username"
              required
              onChange={(e) => setUsernameText(e.target.value)}
              fullWidth
            />
            <FormHelperText error={error}>complete required fields *</FormHelperText>
            <Button
              sx={{
                textTransform: 'none',
                background:
                  (reservationLoading || availabilityLoading || !usernameText || !userIdText) &&
                  '#1776D280 !important'
              }}
              type="submit"
              variant="contained"
              disabled={!usernameText || !userIdText || reservationLoading || availabilityLoading}
              startIcon={
                reservationLoading || availabilityLoading ? (
                  <CircularProgress size={20} sx={{ color: '#fff' }} />
                ) : null
              }>
              Go
            </Button>
          </Box>
        </form>
      </Container>
    </>
  );
}

Dashboard.propTypes = {
  fetchAvailability: PropTypes.any.isRequired,
  fetchReservations: PropTypes.any.isRequired,
  setUsername: PropTypes.any.isRequired,
  reservationLoading: PropTypes.any.isRequired,
  availabilityLoading: PropTypes.any.isRequired,
  setUserId: PropTypes.any.isRequired,
  reservationsError: PropTypes.any,
  availabilityError: PropTypes.any
};

Dashboard.defaultProps = {
  reservationsError: null,
  availabilityError: null
};
