/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { Button, ButtonGroup, ClickAwayListener, Grow, Paper, Popper } from '@mui/material';
import { useRef, useState } from 'react';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';

export const ListStyles = ({ editor }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup size="small" ref={anchorRef} aria-label="small button group">
        <Button
          variant={editor.isActive('blockquote') ? 'contained' : 'outlined'}
          onClick={() => editor.chain().focus().toggleBlockquote().run()}>
          <FormatQuoteIcon fontSize="small" />
        </Button>
        <Button
          variant={editor.isActive('bulletList') ? 'contained' : 'outlined'}
          onClick={() => editor.chain().focus().toggleBulletList().run()}>
          <FormatListBulletedIcon fontSize="small" />
        </Button>
        <Button
          variant={editor.isActive('orderedList') ? 'contained' : 'outlined'}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}>
          <FormatListNumberedIcon fontSize="small" />
        </Button>
        <Button
          variant={editor.isActive('taskList') ? 'contained' : 'outlined'}
          onClick={() => editor.chain().focus().toggleTaskList().run()}>
          <FactCheckOutlinedIcon fontSize="small" />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchor={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <ButtonGroup size="small"></ButtonGroup>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
