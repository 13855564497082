/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useEditor, EditorContent } from '@tiptap/react';
import { useEffect } from 'react';
import Placeholder from '@tiptap/extension-placeholder';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableHeader from '@tiptap/extension-table-header';
import TableCell from '@tiptap/extension-table-cell';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import TextStyle from '@tiptap/extension-text-style';
import FontFamily from '@tiptap/extension-font-family';
import { Color } from '@tiptap/extension-color';
import TextAlign from '@tiptap/extension-text-align';
import Highlight from '@tiptap/extension-highlight';
import Superscript from '@tiptap/extension-superscript';
import Subscript from '@tiptap/extension-subscript';
import Image from '@tiptap/extension-image';
import CharacterCount from '@tiptap/extension-character-count';

import { Extensions } from './Extensions';
import { FloatMenu } from './Extensions/FloatingMenu';
import { BubbleMenu } from './Extensions/BubbleMenu';
import { FontSize } from './Extensions/FontSize';

import './tiptap.css';

export const TextEditor = ({ notes, setNotes, setCharacters, setWords, editable, extensions }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      FontSize,
      Placeholder,
      Table.configure({
        resizable: true
      }),
      TableRow,
      TableHeader,
      TableCell,
      TextStyle,
      FontFamily,
      Color,
      TextAlign.configure({
        types: ['heading', 'paragraph']
      }),
      TaskList,
      TaskItem,
      Highlight,
      Superscript,
      Subscript,
      CharacterCount,
      Image.configure({
        inline: true
      })
    ],
    autofocus: 'start',
    content: notes,
    onUpdate({ editor }) {
      setCharacters && setCharacters(editor.storage.characterCount.characters());
      setWords && setWords(editor.storage.characterCount.words());
      setNotes(editor.getHTML());
    },
    editable: editable
  });
  useEffect(() => {
    setCharacters && setCharacters(editor && editor.storage.characterCount.characters());
    setWords && setWords(editor && editor.storage.characterCount.words());
  }, [editor, setCharacters, setWords]);

  return (
    <>
      {editable && <Extensions editor={editor} extensions={extensions} />}
      {editor && editable && <FloatMenu editor={editor} />}
      {editor && editable && <BubbleMenu editor={editor} />}
      <EditorContent editor={editor} />
    </>
  );
};
