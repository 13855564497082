/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-filename-extension */
/* eslint linebreak-style: ["error", "windows"] */

import { useEffect } from 'react';
import uuid from 'react-uuid';
import lottie from 'lottie-web';
import PropTypes from 'prop-types';

// TODO any styles to be in a css file
export default function Lottie({ url, width = '', height = '' }) {
  const id = uuid();

  useEffect(() => {
    document.getElementById(`lottie${id}`).innerHTML = '';
    lottie.loadAnimation({
      container: document.querySelector(`#lottie${id}`),
      path: url
    });
  }, [url]);

  return <div id={`lottie${id}`} style={{ width: width || '', height: height || '' }} />;
}
Lottie.propTypes = {
  url: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired
};
