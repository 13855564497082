/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-filename-extension */
/* eslint linebreak-style: ["error", "windows"] */

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { useState } from 'react';
import useAxios from 'axios-hooks';
import Dashboard from './routes/Dashboard/Dashboard';
import Reservation from './routes/Reservation/Reservation';
import Test from './routes/Test';
import NotFound from './common/pages/NotFound';

function App() {
  // eslint-disable-next-line max-len
  const [
    { loading: reservationLoading, error: reservationsError, data: reservations },
    fetchReservations
  ] = useAxios({}, { manual: true });
  // eslint-disable-next-line max-len
  const [
    { loading: availabilityLoading, error: availabilityError, data: availability },
    fetchAvailability
  ] = useAxios({}, { manual: true });

  const [username, setUsername] = useState('');
  const [userId, setUserId] = useState('');

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<NotFound />} />
        <Route
          exact
          path="/:userId/:username"
          element={
            <Dashboard
              fetchAvailability={fetchAvailability}
              fetchReservations={fetchReservations}
              setUserId={setUserId}
              setUsername={setUsername}
              availabilityLoading={availabilityLoading}
              reservationLoading={reservationLoading}
              availabilityError={availabilityError}
              reservationsError={reservationsError}
              availability={availability}
            />
          }
        />
        <Route
          path="/Reservation"
          element={
            <Reservation
              availability={availability}
              reservations={reservations}
              username={username}
              userId={userId}
              fetchReservations={fetchReservations}
            />
          }
        />
        <Route path="/test" element={<Test />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
