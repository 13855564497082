/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { Button, ButtonGroup } from '@mui/material';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

export const OtherExtensions = ({ editor }) => {
  return (
    <>
      <ButtonGroup size="small">
        <Button variant="outlined" onClick={() => editor.chain().focus().setHorizontalRule().run()}>
          <HorizontalRuleIcon fontSize="small" />
        </Button>
      </ButtonGroup>
    </>
  );
};
