/* eslint-disable react/react-in-jsx-scope */
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { isBefore, isEqual } from 'date-fns';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

export function stringToColor(string) {
  if (string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
}

export const formatTime = (hours, minutes) => {
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

export const GetFromToString = ({ time, duration }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  let year = new Date(time).getFullYear();
  let month = monthNames[new Date(time).getMonth()];
  let weekDay = new Date(time).toLocaleDateString('en-EN', { weekday: 'long' });
  let day = new Date(time).getDate();
  let fromHours = new Date(time).getHours();
  let fromMinutes = new Date(time).getMinutes();
  let toMinutes;
  let toHours;

  if (duration + fromMinutes >= 60) {
    toHours = fromHours + 1;
    toMinutes = duration + fromMinutes - 60;
  } else {
    toHours = fromHours;
    toMinutes = duration + fromMinutes;
  }
  let fromTimeString = formatTime(fromHours, fromMinutes);
  let toTimeString = formatTime(toHours, toMinutes);

  return (
    <Typography
      fontFamily="Fredoka"
      fontSize={isMobile ? 14 : 15}
      fontWeight={600}>{`from ${fromTimeString} to ${toTimeString}, ${weekDay}, ${month} ${day
      .toString()
      .padStart(2, '0')}, ${year}`}</Typography>
  );
};
GetFromToString.propTypes = {
  time: PropTypes.any.isRequired,
  duration: PropTypes.any.isRequired
};

export const convertDateToStringWithoutTimezone = (date) => {
  //YYYY-MM-DDThh:mm:ss.000Z
  let newDate = new Date(date);
  let year = newDate.getFullYear();
  let month = newDate.getMonth() + 1;
  let day = newDate.getDate();
  let hours = newDate.getHours();
  let minutes = newDate.getMinutes();
  let seconds = newDate.getSeconds();

  return `${year}-${month.toString().padStart(2, 0)}-${day.toString().padStart(2, 0)}T${hours
    .toString()
    .padStart(2, 0)}:${minutes.toString().padStart(2, 0)}:${seconds
    .toString()
    .padStart(2, 0)}.000Z`;
};

export const convertStringDateToUTC = (stringDate) => {
  return new Date(new Date(stringDate).toUTCString().slice(0, -4));
};

export const convertFromUTCToZone = (utcTime, zone) => {
  var jun = moment(utcTime);
  var timeObj = jun.toObject();
  var utc = moment.tz(
    `${timeObj.years}-${timeObj.months + 1}-${timeObj.date} ${timeObj.hours}:${timeObj.minutes}:${
      timeObj.seconds
    }`,
    'YYYY-MM-DD HH:mm:ss',
    'UTC'
  );
  var zonedTime = utc.tz(zone);
  var zoned = moment.tz(
    `${zonedTime.toObject().years}-${zonedTime.toObject().months + 1}-${
      zonedTime.toObject().date
    } ${zonedTime.toObject().hours}:${zonedTime.toObject().minutes}:${
      zonedTime.toObject().seconds
    }`,
    'YYYY-MM-DD HH:mm:ss',
    zone
  );
  return new Date(zoned.toLocaleString().split('GMT')[0]);
};

export const convertFromZoneToUTC = (zonedTimeToConvert, zone) => {
  var jun = moment(zonedTimeToConvert);
  var timeObj = jun.toObject();
  var zonedTime = moment.tz(
    `${timeObj.years}-${timeObj.months + 1}-${timeObj.date} ${timeObj.hours}:${timeObj.minutes}:${
      timeObj.seconds
    }`,
    'YYYY-MM-DD HH:mm:ss',
    zone === 'Etc/GMT' ? 'UTC' : zone
  );
  var utcTime = zonedTime.tz('UTC');
  var utc = moment.tz(
    `${utcTime.toObject().years}-${utcTime.toObject().months + 1}-${utcTime.toObject().date} ${
      utcTime.toObject().hours
    }:${utcTime.toObject().minutes}:${utcTime.toObject().seconds}`,
    'YYYY-MM-DD HH:mm:ss',
    'UTC'
  );
  return new Date(utc.toLocaleString().split('GMT')[0]);
};

export const convertFromZoneToUTCString = (time, zone) => {
  let currentTimeStringWithoutZone = convertDateToStringWithoutTimezone(time);
  let currentTimeInUTC = convertStringDateToUTC(currentTimeStringWithoutZone);
  let currentTimeFromZoneToUTC = convertFromZoneToUTC(currentTimeInUTC, zone);
  let lastTimeInUTCString = convertDateToStringWithoutTimezone(currentTimeFromZoneToUTC);
  return lastTimeInUTCString;
};

export const convertFromUTCStringToZone = (utc, zone) => {
  let utcString = convertStringDateToUTC(utc);
  return convertFromUTCToZone(utcString, zone);
};

export function timeSlotDifference(availableTimeSlots, unavailableTimeSlots) {
  if (!availableTimeSlots || !unavailableTimeSlots) return [];
  const _orderedAvailableTimeSlots = [...availableTimeSlots];
  const _unavailableTimeSlots = [...unavailableTimeSlots];

  //Sorting available time slots
  _orderedAvailableTimeSlots.sort((a, b) => {
    return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
  });

  _unavailableTimeSlots.sort((a, b) => {
    return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
  });

  //Make an cursor
  let cursorIndex = 0;

  //While the cursor still inside the array boundaries
  while (cursorIndex < _orderedAvailableTimeSlots.length) {
    //Catch the current available slot which the curson on
    const availableSlot = _orderedAvailableTimeSlots[cursorIndex];
    try {
      const availableSlotStartTime = new Date(availableSlot.startTime);
      const availableSlotEndTime = new Date(availableSlot.endTime);
      // go ahead and make sure everything has a proper date object
      if (typeof availableSlot.startTime === 'string') {
        availableSlot.startTime = availableSlotStartTime;
      }
      if (typeof availableSlot.endTime === 'string') {
        availableSlot.endTime = availableSlotEndTime;
      }

      //looping over unavailable slots 1 by 1
      for (const unavailableSlot of _unavailableTimeSlots) {
        try {
          const unavailableSlotStartTime = new Date(unavailableSlot.startTime);
          const unavailableSlotEndTime = new Date(unavailableSlot.endTime);
          // go ahead and make sure everything has a proper date object
          if (typeof unavailableSlot.startTime === 'string') {
            unavailableSlot.startTime = unavailableSlotStartTime;
          }
          if (typeof unavailableSlot.endTime === 'string') {
            unavailableSlot.endTime = unavailableSlotEndTime;
          }

          //check if the current unavailable slot startTime before or equal the current available slot startTime
          if (
            isBefore(unavailableSlotStartTime, availableSlotStartTime) ||
            isEqual(unavailableSlotStartTime, availableSlotStartTime)
          ) {
            //check if the current available slot startTime before the current unavailable slot endTime
            if (isBefore(availableSlotStartTime, unavailableSlotEndTime)) {
              //check if the current unavailable time slot endTime before the current available slot endTime
              if (isBefore(unavailableSlotEndTime, availableSlotEndTime)) {
                // |--------[-availableSlot-]---------|
                // |-[---unavailable----]-------------|
                availableSlot.startTime = unavailableSlotEndTime;
              } else {
                // |--------[-availableSlot-]---------|
                // |----[----unavailable-------]------|
                _orderedAvailableTimeSlots.splice(cursorIndex, 1);
                // subtract if we split or splice
                cursorIndex--;
              }
            }
          } else if (isBefore(unavailableSlotStartTime, availableSlotEndTime)) {
            if (isBefore(unavailableSlotEndTime, availableSlotEndTime)) {
              // |------[---availableSlot----]------|
              // |-------[--unavailable---]---------|???????
              const newSlot = {
                ...availableSlot,
                startTime: unavailableSlotEndTime
              };
              availableSlot.endTime = unavailableSlotStartTime;
              _orderedAvailableTimeSlots.splice(cursorIndex + 1, 0, newSlot);
              // subtract if we split or splice
              cursorIndex--;
            } else {
              // |-----[----availableSlot----]------|
              // |-------[----unavailable-------]---|
              availableSlot.endTime = unavailableSlotStartTime;
            }
          }
        } catch (err) {
          console.error('Invalid Date for unavailable slot: ', unavailableSlot);
          throw err;
        }
      }
    } catch (err) {
      console.error('Invalid Date for available slot: ', availableSlot);
      throw err;
    }

    cursorIndex++;
  }
  return _orderedAvailableTimeSlots;
}
