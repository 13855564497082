/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint linebreak-style: ["error", "windows"] */

import React, { useContext, useState, useEffect, useMemo } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import usersDbConfig from '../util/usersDbConfig';

if (!firebase.apps.length) {
  firebase.initializeApp(usersDbConfig, 'usersFirebase');
}

const auth = firebase.app('usersFirebase').auth();
const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [loggedInUser, setLoggedInUser] = useState('');
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const cookies = new Cookies();

  function logout() {
    return auth.signOut();
  }

  function signInAnonymously() {
    return auth.signInAnonymously();
  }
  const TOKEN_NAME = 'token';
  const TOKEN_AGE = 3600;

  useEffect(() => {
    setToken(cookies.get(TOKEN_NAME));
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setLoggedInUser(user);
      if (user) {
        user.getIdToken(true).then((tokenVal) => {
          cookies.set(TOKEN_NAME, tokenVal, { path: '/', maxAge: TOKEN_AGE });
          setToken(tokenVal);
        });
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = useMemo(
    () => ({
      loggedInUser,
      token,
      logout,
      signInAnonymously
    }),
    []
  );

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
AuthProvider.propTypes = {
  children: PropTypes.any.isRequired
};
