/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { Button, ButtonGroup, ClickAwayListener, Grow, Paper, Popper } from '@mui/material';
import { useRef, useState } from 'react';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';

export const CodeStyles = ({ editor }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup size="small" ref={anchorRef} aria-label="small button group">
        <Button
          variant={editor.isActive('code') ? 'contained' : 'outlined'}
          onClick={() => editor.chain().focus().toggleCode().run()}>
          <CodeOutlinedIcon fontSize="small" />
        </Button>
        <Button
          variant={editor.isActive('codeBlock') ? 'contained' : 'outlined'}
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}>
          <TerminalOutlinedIcon fontSize="small" />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <ButtonGroup size="small"></ButtonGroup>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
